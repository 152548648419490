<template>
  <div class="explanation-page">
    <div class="header">
      <router-link class="index-logo" to="/">
        <img class="logo" src="@/assets/images/logo.png" />
      </router-link>
    </div>
    <div class="content-box">
      <div class="content">
        <h1>Terms of Us</h1>
        <br>
        <p>When visiting kizgamer website and/or using its any software, game, you fully agree with
          it on its "Privacy Policy" and "Terms of Use" conditions. If you do not agree on any of these conditions, you
          are not allowed to use the website and/or its services. kizgamer.com has the right to update
          and modify the policies any time. The visitors always should check the related pages to see updates.</p>
        <br><br>
        <b>ACCESS AND INTERFERENCE</b>
        <br>
        <p>You agree that you will not</p>
        <p>(a) use any robot, spider or other automatic device, process or means to access the Site,</p>
        <p>(b) use any manual process to monitor or copy any of the material on this site or for any other unauthorized
          purpose without the prior written consent of the Company,</p>
        <p>(c) use any device, software or routine that interferes with the proper working of the Site,</p>
        <p>(d) attempt to interfere with the proper working of the Site,</p>
        <p>(e) take any action that imposes an unreasonable or disproportionately large load on the Company's
          infrastructure, or</p>
        <p>(f) access, reload or "refresh" transactional pages, or make any other request to transactional servers, more
          than once during any three(3) second interval.</p>
        <br><br><br>
        <b>COPYRIGHT RESTRICTIONS/USE OF CONTENT</b>
        <br>
        <p>The entire contents of this Site (including all information, text, displays, images and audio and any
          software made
          available through or in connection with the Site) and the design, selection and arrangement thereof, are
          proprietary
          to the Company or its affiliates or licensors and are protected by United States and international laws
          regarding
          copyrights, trademarks, trade secrets and other proprietary rights. You are authorized only to use the content
          on
          the Site for personal use related to your role as a current or prospective user of the Site. You may not copy,
          modify, create derivative works of, publicly display or perform, republish, store, transmit or distribute any
          of the
          material on this site without the prior written consent of the Company, except to: (a) store copies of such
          materials temporarily in RAM, (b) store files that are automatically cached by your web browser for display
          enhancement purposes, and (c) print a reasonable number of pages of the Site; provided in each case that you
          do not
          alter or remove any copyright or other proprietary notices included in such materials. Neither the title nor
          any
          intellectual property rights to any information or material in this Site are transferred to you, but remain
          with the
          Company or the applicable owner of such content.</p>
        <br><br><br>
        <b>SOFTWARE AND DOWNLOADS</b>
        <br>
        <p>If the Company offers downloads of, or access to, software on this Site and you download or otherwise access
          such
          software, the software (including any data or images incorporated in or generated by the software) is licensed
          to
          you. You do not receive title to this software and you may not distribute or use the software other than for
          the
          purpose of using the applicable feature or service of the Site as offered by the Company. You may not modify,
          adapt,
          reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of such software.
          Except
          as expressly provided, you may not create any derivative works of the software or any services available on
          the
          Site. This license is revocable at any time without notice and with or without cause. You agree to destroy or
          return
          to the Company all copies of the software upon revocation of your license to the software and/or termination
          of your
          access to the Site. The software is subject to all restrictions on use, disclaimers of warranties and other
          provisions in these Terms of Use. In the event that the software is also subject to a separate end user
          license
          agreement, the terms of such end user license agreement shall control any conflict between those terms and
          these
          Terms of Use.</p>
        <br><br><br>
        <b>LIABILITY OF THE COMPANY AND ITS LICENSORS</b>
        <br>
        <p>The Company does not assume any liability for the materials, information and opinions provided on, or
          available
          through, the Site (the "Site Content"). Reliance on the Site Content is solely at your own risk. The Company
          disclaims any liability for injury or damages resulting from the use of any Site Content.</p>
        <br><br><br>
        <b>THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT ANY WARRANTY OR GUARANTY OF ANY KIND, EITHER
          EXPRESSED
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, AND NON-INFRINGEMENT. THE COMPANY, ITS AFFILIATES AND SERVICE PROVIDERS ARE NEITHER RESPONSIBLE NOR
          LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES ARISING OUT
          OF
          OR RELATING IN ANY WAY TO THE SITE. THE COMPANY SHALL NOT BE HELD LIABLE UNDER THIS AGREEMENT OR OTHERWISE IN
          CONNECTION WITH THE SITE FOR AN AMOUNT MORE THAN $500.</b>
      <Tips />
      </div>
    </div>
  </div>
</template>

<script>
import Tips from "@/components/Tips.vue";
export default {
  components: {  Tips },

}
</script>
<style lang="scss">
@import '~@/assets/scss/explanation';
</style>